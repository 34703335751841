import React from 'react';
import { Link } from 'gatsby';
import Img from '../image';
import './project.css';

function Project({ imageFile, name, date }) {
  return (
    <Link to="/monstechcat/" className="ai-project">
      <Img
        clickDisabled
        className="ai-img-container"
        fluid={imageFile.childImageSharp.fluid}
        alt={name}
      />
      <div className="ai-project__name ai-link-font">{name}</div>
      <div className="ai-project__date ai-link-font">{date}</div>
    </Link>
  );
}

export default Project;
