import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Project from '../components/project/project';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "monstechcat.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Projects" />
      <div className="container">
        <div className="row">
          <div className="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4">
            <Project imageFile={data.file} name="Monstechcat" date="2020" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
